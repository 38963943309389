import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/main.css';
import '../../css/main.scss';
import '../../css/hire-apply.css';
import * as formStyles from './form-thank-you.module.css'

const MainWrapper = styled.div`margin: 0;`;

export default class FormThankYou extends React.Component {
	render() {
		if (this.props.currentStep !== 3) {
			return null;
		}
		return (
			<MainWrapper>
				<div className="register__step">
					<div className="cx_thanyou">
						<div className="row justify-content-center">
							<div className="col-md-12">
								<div className="envelop">
									<StaticImage src="../../images/thank-you.png" alt="thank you" />
								</div>
							</div>
							<div className="col-md-12">
								<h2>Thank You! We’ll Be in Touch.</h2>
							</div>
							<div className="col-md-12">
								<p>
									We appreciate you contacting us <span>codestaff.</span>
									<br /> One of our colleagues will get back in touch with you soon!
								</p>
							</div>
							<div className="col-md-6">
								<a href="{}" className="not-active" >
									<div className={formStyles.phone_button_mock} >
										<StaticImage src="../../images/call.png" alt="phone"  style={{margin: '4px 10px 0 0'}} />855-835-CODE
									</div>
								</a>
								<a href="{}" className="not-active">
                                <div className={formStyles.email_button_mock} >
										<StaticImage src="../../images/envelop.png" alt={'envelope'} style={{margin: '6px 8px 0 0'}} />&nbsp;info@codestaff.io
									</div>
								</a>
							</div>
							<div className="col-md-12">
								<div className="cx_social">
									<a href="https://twitter.com/code_staff">
										<FontAwesomeIcon icon={faTwitter} />
									</a>
									<a href="https://www.linkedin.com/company/code-staff">
										<FontAwesomeIcon icon={faLinkedin} />
									</a>
									<a href="https://www.facebook.com/codestaff">
										<FontAwesomeIcon icon={faFacebook} />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</MainWrapper>
		);
	}
}
