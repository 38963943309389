import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/main.css'
import '../../css/main.scss'
import '../../css/hire-apply.css'

export default class HireStep2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            desc: ''
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value})
        this.props.handleChange(event)
    }

    render() {
        if (this.props.currentStep !== 2) {
            return null
        }
        return (
            <React.Fragment>
                <div className="register__step-intro">
                    <h4>Step 2 of 3</h4>
                    <h2>Description</h2>
                    <p className="p--md u-font-alt-2">Please enter all important information relating to your job description.</p>
                </div>
                <div className="form register__step-main">
                    <div className="row">
                        <textarea
                            style={{width: '100%'}}
                            id='desc'
                            name='desc'
                            rows="5"
                            placeholder="Please input your job description"
                            value={this.state.desc}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
