import React from 'react'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/main.css'
import '../../css/main.scss'
import '../../css/hire-apply.css'
import Banner_bg_network_jpg from '../../images/banner-bg-network.jpg'

const MainWrapper = styled.div`
  margin: 0;
`
const inactive = "steps__item"
const isactive = "steps__item is-active"

export default class FormBanner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bannerstep: props.bannerstep
        }
    }

    render() {
        let step1steps__item = isactive
        let step2steps__item = (this.props.bannerstep > 1) ? isactive : inactive
        let step3steps__item = (this.props.bannerstep > 2) ? isactive : inactive
        return (
            <MainWrapper>
                <section className="banner-new" style={{ backgroundImage: `url(${Banner_bg_network_jpg})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner_iner">
                                    <div className="banner_sub">
                                        <ol className="steps register__process">
                                            <li className={step1steps__item}>
                                                <div className="steps__item-number">
                                                    <p className="p--lg u-color-white u-weight-bld">1</p>
                                                </div>
                                                <div className="steps__item-copy">
                                                    <p className="p--md u-color-white u-font-alt-2">{this.props.step1label}</p>
                                                </div>
                                            </li>
                                            <li className={step2steps__item}>
                                                <div className="steps__item-number">
                                                    <p className="p--lg u-color-white u-weight-bld">2</p>
                                                </div>
                                                <div className="steps__item-copy">
                                                    <p className="p--md u-color-white u-font-alt-2">{this.props.step2label}</p>
                                                </div>
                                            </li>
                                            <li className={step3steps__item}>
                                                <div className="steps__item-number">
                                                    <p className="p--lg u-color-white u-weight-bld">3</p>
                                                </div>
                                                <div className="steps__item-copy">
                                                    <p className="p--md u-color-white u-font-alt-2">{this.props.step3label}</p>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="banner_main">
                                        <h3>{this.props.title}</h3>
                                        <h4>{this.props.subtitle}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </MainWrapper>
        )
    }
}