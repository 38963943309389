import React from 'react'
import Layout from '../components/layout'
import Hire from '../components/forms/hire'
import Seo from '../components/seo'

const HirePage = ({ location }) => {
  return (
    <Layout location={location}>
      <Hire />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title =
    'Hire Top Freelancers, Developers, Programmers, Designers & Web Pros'
  const description =
    'Fill out this quick survey to start your journey to find the perfect Freelancer, Developer, Programmer, Designer & Web Professional.'
  return <Seo type="page" title={title} description={description} />
}

export default HirePage
