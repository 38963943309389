import React from 'react'
import FormBanner from './form-banner'
import HireStep1 from './hire-step-1'
import HireStep2 from './hire-step-2'
import FormThankYou from './form-thank-you'
import { StaticImage } from 'gatsby-plugin-image';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/main.css'
import '../../css/main.scss'
import '../../css/hire-apply.css'

export default class Hire extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef()
        this._next = this._next.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.submitPostgresql = this.submitPostgresql.bind(this)

        this.state = {
            currentStep: 1,
        }

        this.store = {
            fname: '',
            lname: '',
            phone: '',
            email: '',
            linkedin: '',
            desc: ''            
        }

    }

    submitPostgresql() {
        this.setState({ 'postgres': 'called' },
            () => {
                const formFields = {}
                formFields.firstname = this.store.fname
                formFields.lastname = this.store.lname
                formFields.phone = this.store.phone
                formFields.email = this.store.email
                formFields.linkedin_url = this.store.linkedin
                formFields.job_description = this.store.desc
                formFields.fullname = `${this.store.fname} ${this.store.lname}`.trim()
                formFields.segment_id = 8

                fetch('/.netlify/functions/postgresqlSubmission', {
                    method: 'POST',
                    headers: { 'Content-Type': 'text/html; charset=utf-8' },
                    body: JSON.stringify({
                        'formName': 'hire',
                        formFields,
                    }),
                }).catch((error) => alert(error))
            }
        )
    }

    _next() {
        let isValid = this.form.current.reportValidity();
        if (isValid) {
            if (this.state.currentStep === 2) {
                this.submitPostgresql()
            }
            this.setState({ currentStep: this.state.currentStep + 1})

        } 
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 3) {
            return (
                <div className="register__actions">
                    <button onClick={this._next}
                        className="register__btn-next btn py-3 u-font-alt-2 u-weight-smb ml-3">
                        NEXT
                        <StaticImage src='../../images/i-arrow-right.png'
                            alt='Arrow pointing to the right'
                            className='ml-5 btn__arrow' />
                    </button>
                </div>
            )
        }
        return null
    }

    handleChange = (event) => {
        this.store[event.target.id] = event.target.value
    }
      
    render() {
        return (
            <React.Fragment>
                <FormBanner bannerstep={this.state.currentStep}
                    title="Hire Top Talent"
                    subtitle="Find an Experienced Expert to help you with your project."
                    step1label="Contact Info"
                    step2label="Job Description"
                    step3label="Submit and Schedule"
                />
                <section className="register">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="register__box">
                                    <div className="box p-5">
                                        <div className="register__main">
                                            <div className="register__step cx_starbox">
                                                <form ref={this.form} className="form register__step-main" onSubmit={(e) => e.preventDefault()} >
                                                    <HireStep1 currentStep={this.state.currentStep} handleChange={this.handleChange} />
                                                    <HireStep2 currentStep={this.state.currentStep} handleChange={this.handleChange} />
                                                    <FormThankYou currentStep={this.state.currentStep} />
                                                    {this.nextButton}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
